

.Promotions {
    margin-top: 50px;
    position: relative;
    width: 100%;
}

Promotions .promotionSignUp {
    width: 100%;
}