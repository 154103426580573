  .favorites {
    position: relative;
    margin-top: 45px;
  }
  .favRow{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  @media(max-width:560px){
    .favRow{
      margin-left: 0 !important;
    }
  }