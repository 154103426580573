
.container{
    max-width: inherit;
    padding: inherit;
    margin: inherit;
}

 .video-container{
  /* position:absolute; */
  height:100%;
  width:100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  object-fit: cover;
  z-index: 999;
}

.video-container video {
    min-height: 100%;
    object-fit: cover;
    height:auto;
    position: relative;
} 