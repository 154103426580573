.canvasStylesHome{
    padding: 10px;
    width: 50%;
    height: 80vh;
    
}
.canvasStylesHome canvas{
    border-radius: 25px !important;
    box-shadow: 0 0 30px #333;
}

@media (max-width:599px){
    .canvasStylesHome{
        width: 100%;
        height: 80vh;
    }
}