.App {
  text-align: center;
}

.container {
  display: flex;
    flex-direction: column;
    min-height: 38vh;
  position: relative;
  z-index: 10;
}

.content {
  position: relative;
  z-index: 10;
}

Header {
  position: relative;
  z-index: 100;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:  rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.PromotionalSignUp {
  margin-top: 20px;
  width: 100%;
  position: relative;
}
.message-box,
.messageBox, 
.message-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-bubble div {
  margin-bottom: 5px;
}