.labelFooter{
    font-family: Arial;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.footerHeader{
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
}
.footerExpand{
    padding-top: 10px;
}
.footerExpand ul {
    list-style-type: none;
    /* height: 184px; */
    width: 155.8125px;
    left: 0px;
    top: 43px;
    border-radius: 0px;
    padding: 0px 7px 0px 0px;
}

.footerExpand ul li {
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.labelFooter1 svg:not(:nth-child(1)){
    margin-left: 6px;
}

.footerLow{
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    background: #262626;
    height: 53px;
    left: 0px;
    top: 343px;
    border-radius: 0px;
    padding: 16px 50px 16px 50px;

}

.footerCountry{
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}
.leftImage{
    background: url('../assets/images/footerFlag.png');
    height: 18px;
    background-repeat: no-repeat;
    border-radius: 0px;
}
.leftFooter{
    /* margin-right: 4px; */
    display: inline;
}
.footerRightCopy{
    color: white;
}
@media(max-width:801px){
    .footerExpand ul {
        display: none;
      }
      .footerExpand{
        margin-left: 10px;
      }
      .footerExpand.show ul {
        display: block;
      }
.lineArea{
  height: 1px;
  width: 100%;
  border: 1px solid #0f0f0f;
}
      
      
      .arrow {
        display: inline-block;
        width: 20px;
        height: 20px;
        float: right;
        /* border: 2px solid black; */
        transform: rotate(45deg);
        transition: transform 0.3s ease-in-out;
      }
      
      .arrow::before,
      .arrow::after {
        content: "";
        display: block;
        position: absolute;
        background-color: black;
      }
      
      .arrow::before {
        width: 87%;
        height: 2px;
        top: 62%;
        left: 15%;
      }
      
      .arrow::after {
        width: 2px;
        height: 92%;
        top: 20%;
        left: 50%;
      }
      
      .arrow.up {
        transform: rotate(45deg);
      }
      
      .arrow.down {
        transform: rotate(0deg);
      }
      
      
      

      .leftFooter1{
        margin-left: -10px;
      }
      .leftFooter2{
        margin-right: -50px;
      }
    .footerHeader{
        padding-left: 30px;
    }
}
