.messageBox {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  /* width: 320px;
  height: 400px; */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.playButton {
  position: absolute;
  right: 25px;
  cursor: pointer;
  width: 16px;
}
.playButton:hover {
  width: 20px;
}
.responseImage,
.responseAudio {
  width: 100%;
}

.messageIconContainer1 {
  cursor: pointer;
}
.messageIconContainer1 svg {
  position: relative;
  top: 3px;
  left: 2px;
}
.messageIconContainer1:hover {
  border-radius: 20px;
  background: #128c7e;
  width: 7%;
}

.messageIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f5f6f8;
  cursor: pointer;
}

.messagePopup {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  max-height: 424px;
  max-width: 414px;
}

.popupHeader {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #4267b2;
  color: #ffffff;
}

.popupTitle {
  font-size: 16px;
  font-weight: bold;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.msgContent {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 10px;
  padding-right: 10px;
}

.conversation {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  background-color: #128c7e;
  width: 80%;
  padding: 10px;
  border-radius: 12px;
}

.userMessage {
  background-color: #dcf8c6 !important;
  color: #000000;
  align-self: flex-end !important;
  border-radius: 12px;
  padding: 10px;
  max-width: 80%;
}

.responseMessage {
  background-color: #e5e5ea;
  color: #000000;
  align-self: flex-start;
  border-radius: 12px;
  padding: 10px;
  max-width: 80%;
}

.msgFormSubmit {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.msgInput {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.msgSendBtn {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #4267b2;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.lockedIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.messageIcon {
  fill: #4267b2;
}

.closeIcon {
  fill: #ccc;
  width: 16px;
  height: 16px;
}

.lockIcon {
  fill: #ccc;
  width: 25px;
  height: 22px;
}

/* record Button styles */
/* Styles for the record button */
.recordButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 2px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}

.recordButton:hover {
  background-color: green; /* Darker shade of red on hover */
}

/* Styles for the recording indicator dot inside the button */
.recordButton::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff; /* White color for the indicator dot */
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.recordButton.active::after {
  opacity: 1; /* Show the indicator dot when the button is active */
}

/* Styles for the recording pulsating animation (optional) */
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

.recordButton.active::before {
  content: "";
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #dc143c;
  opacity: 0.8;
  animation: pulsate 1.5s infinite;
}

.msgContent::-webkit-scrollbar {
  width: 8px;
  margin-left: 4px;
}

.msgContent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.msgContent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.msgContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 500px) {
  .msgInput {
    max-width: 200px !important;
  }
  .messagePopup {
    max-width: 340px !important;
  }
}
