.profile {
    position: relative;
    margin-top: 40px;
    margin-left: 0 !important;
}
.profile h1{
    margin-left: 7.5%;
}
.rowTiles{
    margin-right: 50px;
}
.profileTile:hover{
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(171,166,171,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(171,166,171,1);
    box-shadow: 10px 10px 5px 0px rgba(171,166,171,1);
}
.profileTile{
    /* margin:6px; */
    height: 102px;
    width: 320px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #D5D9D9; 
}

.profileLabel{
    height: 20.5px;
    width: fit-content !important;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    display: inline-block !important;
    margin-bottom: 0.5rem !important;
    align-items: center;

    color: #111111;
}

.profileSpan{
    width: 189px;
height: 40px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

align-items: center;

color: #565959;


}

.profileTile{
    margin: 6px;
}