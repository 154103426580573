.scroll-text {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    height: auto;
}

.scroll-text::after {
    content: "";
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    height: calc(100% - 45px);
    background-color: black;
    z-index: -1;
}

.scroll-text h2 {
    display: inline-block;
    margin-top: 45px;
    animation: scroll-left 15s linear infinite;
    font-size: 1em;
    line-height: 1.2em; 
    font-weight: bold;
    font-family: 'Courier New', monospace;
    color: #f00;
    text-shadow: 0 0 5px #f00, 0 0 10px #f00, 0 0 20px #f00, 0 0 30px #f00;
    margin-bottom: 0;
    padding: 0;
}

@keyframes scroll-left {
    from {
        transform: translateX(200%);
    }
    to {
        transform: translateX(-200%);
    }
}
