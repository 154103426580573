.popUpLabel{
    font-family: 'Helvetica Neue';
    font-weight: 700;
    font-size: 28px;
    text-align: center;
}
.inputTag{
    border-radius: 12px;
    height: Hug (56px);
}
.fromRowaccoutn{
    margin-left: -15px;
}
.phoneWarning{
    font-family: 'Helvetica Neue';
    font-size: 12px;
    font-weight: 400;
}
.subText{
    line-height: 1;
}
.numberTag{
    margin-bottom: 0 !important;
}
.submitBtn{
    border-radius: 12px;
}
.submitTag{
    margin-top: 6px;
}

.cancelTag{
    text-align: center;
}

.cancelTxt{
    text-align: center;
    color: #0066CC;
    cursor: pointer;
}

#number::-webkit-outer-spin-button,
#number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}