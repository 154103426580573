.CollectionPage {
    background-color: grey; 
    position: relative;
    top:45px;
}
.colabSubHeader{
    
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #121619;
}
.colabWarn{
    font-family: 'IBM Plex Sans';
font-style: italic;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin-top: 5%;
display: flex;
align-items: center;

color: #000000;
}
.formRow{
    margin-top: 2%;
}