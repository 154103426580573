.BlogPage {
    position: relative;
    top:45px;
    margin-right: 0px;
    /* margin: 100px; */
}

.blog-main {
    display: flex;
    height: 875px
}

.blog-left {
    width: 50%;
    height: 100%;
}

.brand-name {
    margin-top: 20%;
}
.brand-name h1 {
    font: 55em optima;
    font-size:55px;
    margin-bottom: 0;
}

.brand-name h3 {
    margin-top: 0px;
    font: 55em optima;
    font-size:25px;
}

.blog-vision {
    margin-top: 20%;
    width: 50%;
    margin-left: 25%;
}

.blog-vision h3{
    font: 55em optima;
    font-size: 25px;
}

.blog-vision p{
    font: 55em optima;
    font-size: 18px;
}

.blog-right {
    width: 50%;
    height: 100%;
}

.blog-right img {
    height: 100%; 
    width: 100%; 
    object-fit: cover;



}

