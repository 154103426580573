.removeSpace {
    min-width: 1%;
}

.largeLabel {
    font-family: Arial;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}
.removeFooterMargin{
    margin-left: 0 !important;
}
.promotion-top {
    margin: 0;
    padding: 60px 0px;
    background-color: #D3D3D3;
    justify-content: space-around;
}

.labelContent {
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.labelContent1 {
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}

.removePadding {
    padding-left: 0;
}

.removePaddingr {
    padding-right: 0;
}

.rightTick {
    height: 13.69999885559082px;
    width: 19.299999237060547px;
    left: 0.35009765625px;
    top: 3.366668701171875px;
    border-radius: 0px;

}

.inputPromotion {
    height: 54px;
    width: 100%;
    left: 1px;
    top: 1px;
    border-radius: 6px;
    padding: 18px 24px 18px 24px;

}

.form-control1 {
    display: block;
    /* width: 100%; */
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.tikIcon {
    text-align: left;
}