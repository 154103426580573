.collabration {
    position: relative;
    margin-top: 45px;
}

.rowTemp{
    margin-right: 50px !important;
}
.colabLabel{
    height: 16px;
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;

    color: #525252;
}

.colabInput{
    box-sizing: border-box;
    height: 40px;
    border: 0;
    background: #F4F4F4;
    border-bottom: 1px solid #8D8D8D;
}
.colabInput1{
    height: 103px !important;
}
.colabAgreement{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.16px;
    color: #000000;
}
input .colabInput:focus-visible{
    border: 0;
    background: #F4F4F4;
    border-bottom: 1px solid #8D8D8D;
}

.colabSubmit{

}