.sectionDivtow{
    width: 50%;
    height: 80vh;
    padding: 10px;
    /* filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5)); */
    /* filter: drop-shadow(0.6rem 0.6rem 1rem rgba(0, 0, 0, 0.8)); */

}
.sectionDivtow .productHomeDiv{
    box-shadow: 0 0 30px #333;

}
.productHomeDiv{
    border-radius: 25px;
    justify-content: center;
    align-content: center;
    border: 1px solid #000;
    background-color: rgba(0,0,0,.8);
    color: white;
    width: 100%;
    height: 78vh;
    padding: 10px !important;
}
.productHomeDivChild{
    /* top:20%; */
    height:fit-content;
    position:relative;
}
.productVisit{
    height: fit-content;
    text-align: center;
}
.shopLogo{
    width: 50%;
    height: 250px;
    opacity: 1; 
}
.shopLogo:hover{
    opacity: .7;
}

@media (max-width:599px){
    .sectionDivtow{
        width: 100%;
    }
    .productHomeDiv{
        /* height: 80vh; */
        width: 100%;
    }
}