.Banner {
    background-color: black; 
    position: relative;
    top:45px;
    height: 45;
    color: red;
}

.filter{
    margin-right:50px !important;
    margin-top: 45px;
}

.products {
    position: relative;
    /* top: 40px; */
  }

select{
    max-width: 100%;
    min-height: 40px;
}
.selectDiv{
    padding-top: 8px;
}