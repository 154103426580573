.videoMessageBox{
    position: fixed;
    bottom: 90px;
    right: 20px;
    z-index: 999;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.videoIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f5f6f8;
    cursor: pointer;
  }

  .closeIcon {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    fill: #000;
  }
  .videoTitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }  

  .videoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    /* padding: 10px; */
    width: 100%;
    background-color: #f1f1f1;
  }

  .videoControls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .previousButton,
  .nextButton {
    background-color: #f2f2f2;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .previousButton:hover,
  .nextButton:hover {
    background-color: #e6e6e6;
  }
  
  .previousButton:disabled,
  .nextButton:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .previousButton:focus,
  .nextButton:focus {
    outline: none;
  }


  