/* Header Bar  */

.header-bar {
  background: rgba(0, 0, 0, 0.8);
  height: 45px;
  /* z-index: 300; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  z-index: 500;
}

.linksSvg {
  display: flex !important;
}

/* overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* Navigation Bar - Left Section */

.header-bar .nav-left {
  width: 35%;
  height: 100%;
}

nav .nav-left-interior {
  display: flex;
  margin: 0;
  padding: 0 15px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.nav-left ul {
  list-style: none;
}

.nav-left ul li a {
  color: white;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.5;
}

.nav-left ul li a:hover {
  opacity: 0.8;
}

.nav-left ul li {
  padding-left: 8px;
  padding-right: 8px;
}

.nav-left ul li .nav-shop {
  padding-left: 16px;
}

/* Navigation Bar - Right Section */

.header-bar .nav-right {
  width: 35%;
  height: 100%;
}

nav .nav-right-interior {
  display: flex;
  margin: 0;
  padding: 0 15px;
  justify-content: flex-end;
  align-items: center;
  height: 100%
}

.nav-right ul {
  list-style: none;
}

.nav-right ul li {
  padding-left: 8px;
  padding-right: 8px;
}

.nav-search {
  background: url("../assets/images/search-icon.svg");
  display: block;
  background-position: center;
  background-size: 18px;
  height: 45px;
  width: 20px;
  background-repeat: no-repeat;
  opacity: 0.5;
  cursor: pointer;
}

.searchPadding {
  padding-bottom: 4px;
}


.nav-search:hover {
  opacity: 0.8;
}


.nav-bag {
  background: url("../assets/images/bag-icon.svg");
  display: block;
  background-position: center;
  background-size: 17px;
  height: 45px;
  width: 20px;
  background-repeat: no-repeat;
  opacity: 0.5;
  cursor: pointer;
}

.nav-bag:hover {
  opacity: 0.8;
}

.nav-right ul li .nav-bag {
  padding-right: 16px;
}


/* Navigation Bar - Middle Section */

.header-bar .nav-middle {
  margin: 0;
  padding: 0%;
  width: 30%;
}

.nav-middle ul {
  list-style: none;
}

nav .nav-middle-interior {
  display: flex;
  margin: 0;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-middle ul li a {
  color: white;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.5;
}

.nav-middle ul li a:hover {
  opacity: 0.8;
}

/* Search Container */

.search-container.hide {
  opacity: 0;
  pointer-events: none;
}

.search-container {
  width: 60%;
  margin: 0 auto;
  position: relative;
  padding: 0 42px;
  z-index: 300;
}

.link-search {
  background: url("../assets/images/search-icon.svg");
  display: block;
  background-position: center;
  background-size: 18px;
  height: 45px;
  width: 20px;
  background-repeat: no-repeat;
}

.search-container .link-search {
  position: absolute;
  top: 0;
  left: 12px;
  opacity: 0.5;
  margin: 0 auto;
}

.search-container .link-close {
  position: absolute;
  top: 0;
  right: 12px;
  opacity: 0.5;
  cursor: pointer;
  transition: all 400ms;
}

.search-container .link-close:hover {
  opacity: 0.8;
}

.search-container form {
  width: 100%;
  margin: 0 auto;
  border: 0;
}

.search-container form input {
  width: 100%;
  height: 45px;
  border: 0;
  outline: none;
  background: transparent;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 17px;
}

.link-close {
  background: url("../assets/images/close-icon.svg");
  display: block;
  background-position: center;
  background-size: 17px;
  height: 45px;
  width: 20px;
  background-repeat: no-repeat;
}

/* Quick Links */
.search-container .quick-links {
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  padding: 8px 0;
  border-radius: 0 0 16px 16px;
}

.search-container .quick-links h2 {
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  color: #6e6e73;
  margin: 0 32px;
  margin-top: 8px;
}

.search-container .quick-links ul {
  text-align: left;
  list-style: none;
  margin-top: 12px;
  padding: 0;
}

.search-container .quick-links ul li a {
  display: inline-block;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 40px;
  font-size: 14px;
  color: #1d1d1f;
  text-decoration: none;
  font-weight: 400;
}

.search-container .quick-links ul li a:hover {
  background: #f5f5f5;
  color: #2997ff;

}

/* Cart Container */

.cart-container.hide {
  opacity: 0;
  pointer-events: none;
}

.cart-container {
  width: 100%;
  left: 0;
  margin: 0 auto;
  top: 45px;
  position: fixed;
  padding: 0 0px;
  z-index: 999999;
}

/* Quick Links */
.cart-container .quick-links {
  background: #fff;
  position: relative;
  left: 0;
  right: 0;
  padding: 8px 0;
  border-radius: 0 0 16px 16px;
}

.cart-container .quick-links h2 {
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  color: #6e6e73;
  margin: 0 32px;
  margin-top: 8px;
}

.cart-container .quick-links h2:hover {
  color: #2997ff;
}

.cart-container .quick-links h4 {
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  color: #6e6e73;
  margin: 0 32px;
  margin-top: 8px;
}

.cart-container .quick-links h4:hover {
  color: #2997ff;
}

.cart-container .quick-links ul {
  text-align: left;
  list-style: none;
  margin-top: 12px;
  padding: 0;
}

.cart-container .quick-links ul li a {
  display: inline-block;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 40px;
  font-size: 14px;
  color: #1d1d1f;
  text-decoration: none;
  font-weight: 400;
}

.cart-container .quick-links ul li Link {
  display: inline-block;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  font-size: 14px;
  color: #1d1d1f;
  text-decoration: none;
  font-weight: 400;
}

.cart-container .quick-links ul li a:hover {
  background: #f5f5f5;
  color: #2997ff;
}

/* Testing */

.ac-gn-bag-badge {
  background: #fff;
  border-radius: 1.8em;
  right: 20px;
  position: absolute;
  bottom: 11px;
  z-index: 1;
  width: 1.3em;
  height: 1.3em;
  box-sizing: border-box;
  float: none;
  color: #1d1d1f;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.008em;
  line-height: 1.3;
  text-align: center;
}

.nav-left-interior .icon {
  display: none;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 660px) {
  .listIt li:not(:first-child) {
    display: none;
  }

  .nav-left-interior li.icon {
    float: right;
    margin-right: 94%;
    display: block;
  }
}

@media screen and (max-width: 660px) {

  .nav-left-interior {
    flex-direction: column;
    margin-top: 14px;
    width: 286%;
    padding: 0 !important;
    /* background: black !important; */

  }

  .nav-left-interior.responsive {
    position: relative;
    min-height: 100vh !important;
  }

  .nav-left-interior .responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav-left-interior .responsive li {
    float: none;
    display: block !important;
    text-align: left;
  }

  .icon {
    text-align: center;
    font-size: 26px;
    color: #fff;
    opacity: .5;
    cursor: pointer;

    transition: all 1.5s;
  }

  .icon:hover {
    opacity: .8;
  }

  .responsive li:not(:first-child) {
    padding: 10px;
    background-color: black;
    animation: FadeIn .2s linear;
    animation-fill-mode: both;
    width: 100%;
  }


}

label {
  display: flex;
  flex-direction: column;
  width: 20px;
  /* Adjusted to be smaller */
  cursor: pointer;
  justify-content: center;
  /* Center the items vertically */
  height: 45px;
  /* Match the header height */
}

label span {
  background: #fff;
  border-radius: 10px;
  height: 2px;
  /* Adjusted to be smaller */
  margin: 2px 0;
  /* Adjusted to be smaller */
  transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

span.top-line {
  width: 50%;
}

span.middle-line {
  width: 100%;
}

span.bottom-line {
  width: 75%;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked~span.top-line {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(2px, 0px)
    /* Adjusted to be smaller */
}

input[type="checkbox"]:checked~span.middle-line {
  transform-origin: top;
  transform: rotatez(-45deg)
}

input[type="checkbox"]:checked~span.bottom-line {
  transform-origin: bottom;
  width: 50%;
  transform: translate(10px, -4px) rotatez(45deg);
  /* Adjusted to be smaller */
}