.stocktreemap svg {
  display: block;
  background: white;
  border: solid 1px #ccc;
  cursor: grab;
}

.stocktreemap svg:active {
  cursor: grabbing;
}

/* .stocktreemap g {
  transition: transform 0.5s;
} */