  .accounts {
    position: relative;
    margin-top: 45px;
  }

  .labelsCol {
    text-align: left;
  }

  .labels {
    text-align: left;
    font-family: "Helvetica Neue";
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2160000056028366px;
  }

  .labelRow {
    margin-top: 14px;
  }

  .mainLabelCol {
    text-align: left;
    padding-bottom: 20px;
  }

  .mainLabel {
    font-family: "Helvetica Neue";
    font-size: 29px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.12800000607967377px;
    text-align: left;
  }

  .subLabel {
    font-family: "Helvetica Neue";
    font-size: 17px !important;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.37400001287460327px;
    text-align: left;
  }

  .row {
    margin-left: 100px;
    /* margin-right: 50px; */
  }

  .content {
    font-family: Helvetica Neue;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.37400001287460327px;
    text-align: left;

  }

  @media (max-width:560px){
    .row{
    margin-left: 0;}
  }