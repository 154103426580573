.textAlign{
    text-align: left;
    padding: 20px;
}
.btn-color , .btn-color:active{
    border:1px solid #FF5E00;
    background: #FF5E00 !important;
}
.cartEmpty{
    position: relative;
    /* top: 2rem; */
}

.btnBag{
    display: block;
    position: relative;
    top: 1rem;
}
.btnPad{
    padding: 22px;
}
.discount{
    color: #D82C19;
}
.btn-color:hover{
    background: #FF5E50 !important;
}
.divs{
    text-align: left;
    padding-top:20px;
    padding-bottom: 20px;
    gap:10px;
    border-bottom: 1px solid black;
}

.fixSize{
    max-width: 12.667% !important;
}
.removeButton{
    display: flex;
    align-items: center;
}
.itemProp{
    padding-top: 26px;
}
.cart1, .price1{
    display: flex;
    align-items: center;
    gap: 6px;
}
.btn1{
    border: 1px solid black;
}
.price1,.price2{
    font-weight: 700;
}

@media (max-width:570px){
    .cart1{
        margin-left: 20px;
    }
}