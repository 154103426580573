.product-tile {
  /* margin-top: 40px; */
  width: 200px;
  /*height: 300px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.product-image {
  width: 180px;
  height: 220px;
  position: relative;
}

.dialogAddToCartButton {
  font-size: 16px !important;
  /* Default font-size */
}

.removeMargin {
  margin-left: 0 !important;
}

.dialogAddToCartButton.smallScreen {
  font-size: 14px;
  /* Font-size for small screens */
}

.dialogActions {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.dialogAddToCartButton {
  /* width: 20%; */
  /* font-size: 14px !important; */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.product-details {
  text-align: center;
}

.product-name {
  font-family: "Tenor Sans";
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 14.039999008178711px;
  margin-bottom: 5px;
}

.product-price {
  font-family: "Tenor Sans";
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17.549999237060547px;
}

.color-choices {
  display: flex;
  gap: 5px;
}

.color-choice1,
.color-choice2,
.color-choice3,
.color-choice4 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.add-to-cart-button,
.favorite-button {
  padding: 5px 10px;
  font-family: "Tenor Sans";
  font-size: 12px;
  font-weight: 400;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.favorite-button {
  background-color: #dc3545;
}


.favorite-message {
  font-family: "Tenor Sans";
  font-size: 12px;
  font-weight: 400;
  color: green;
  margin-top: 5px;
}

.popUpTitle {
  font-family: Arial;
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.popUpDescription {
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

}

.popUpPrice {
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.popUpPrice::before {
  content: '$';
}

.pricePopupRow {
  align-items: center;
  justify-content: center;
}

.popUpAddButton {
  width: 100%;
  height: 52px;
  /* padding: 16px 204.14999389648438px 16px 204.44000244140625px; */
  color: #fff;
  border-radius: 52px;
  background-color: #000;
}

.popUpPriceLine {
  width: 80%;
  color: gray;
}

.popupShippingContent {
  margin-top: 20px;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

}

.popUpInputSize {
  margin-top: 20px;
  height: 40px;
  width: 68%;
}

.popUpSizeButton {
  max-width: 30%;
  border: 1px solid black;
  margin-top: 20px;
  height: 40px;
  font-size: 12px;
  border-radius: 0;
}

.popupSimilar {
  font-family: Noto Sans JP;
  font-size: 13px;
  font-weight: 350;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.popupOtherVersion {
  margin-top: 20px;
}

.popupBottomInstructions {
  margin-top: 20px
}

.popupInstructions {
  border-radius: 5px;
  border: 1px solid #CECECE;
}

.borderInfoOne {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
  /* 161.538% */
  text-decoration-line: underline;
}

.infoOneBorder {
  margin-top: 8px;
}

.arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow .arrowSpan {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: #000;
  /* Change this to the desired color */
  transition: transform 0.2s ease-in-out;
}
.arrowSpan{
  margin-left: 5px;
}
.arrow.expanded .arrowSpan {
  transform: rotate(180deg);
  margin-left: 8px;
  margin-top: 4px;
}

.popupDescriptionBox {
  border-radius: 5px;
  background: #F6F6F6;
}

.descriptionTitle {
  color: #000;
  font-family: Noto Sans JP;
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
  /* 166.667% */
  text-transform: uppercase;
}
.popupDescriptionBox{
  margin-top: 20px;
}
.desctiptionEachEle{
  margin-top: 10px;
}
.desctiptionEachEle:hover,
.borderInfoOne:hover{
  box-shadow: 0 0 5px 5px #f0f0f0;
}
.desctiptionEachEle:nth-child(3){
  margin-bottom: 10px;
}
.firstElementpopBottom{
  margin-top: 8px;
}
.lastElementpopBottom{
  margin-bottom: 8px;
}

.popUpAddButton:hover{
  box-shadow: 0 0 5px 5px #000;
}
@media (max-width: 570px) {
  .popupTitle {
    font-family: Arial;
    font-size: 7px !important;
    font-weight: 400;
    line-height: 1px !important;
    letter-spacing: 0em;
    text-align: left;
  }

  .popUpDescription {
    font-family: Arial;
    font-size: 5px !important;
    font-weight: 200 !important;
    line-height: 0px !important;
    letter-spacing: 0em;
    text-align: left;
  }
}