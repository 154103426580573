.favorite-button1{
    padding: 5px 10px;
    font-family: "Tenor Sans";
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: #dfdfdf;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}